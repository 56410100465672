import React from 'react';

import HorizontalNav3 from '../components/horizontal-navs/HorizontalNav3';
import Header5 from '../components/headers/Header5';
import Features3 from '../components/features/Features3';
import Team1 from '../components/team/Team1';
import Pricing2 from '../components/pricing/Pricing2';
import Content2 from '../components/content/Content2';
import Contact1 from '../components/contacts/Contact1';
import Footer3 from '../components/footers/Footer3';

export default function Index() {
  return (
    <React.Fragment>
      <HorizontalNav3 content={null} />

      <Header5 content={null} />

      <Features3 content={null} />

      <Team1 content={null} />

      <Pricing2 content={null} />

      <Content2 content={null} />

      <Contact1 content={null} />

      <Footer3 content={null} />
    </React.Fragment>
  );
}

